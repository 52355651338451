<template>
  <div
    id="xppCommentContent"
    class="xpp-comment-container"
    ref="xppCommentContent"
    v-if="dialogElementShow.xppCommentShow"
    :style="xppCommentOffset"
  >
    <div id="xppCommentContentHead" class="display-flex">
      <span>评论</span>
      <span
        v-if="elementCommentInfo.list.length && paperOperateMode === 2"
        class="iconfont icon-delete"
        title="删除所有"
        @click="delCommentAll"
      ></span>
    </div>
    <div id="xppCommentContentBody" ref="xppCommentContentBody">
      <div
        class="xpp-comment-item"
        v-for="item in commentInfoList"
        :key="item.id"
      >
        <div
          id="repeatEditCommentForm"
          ref="repeatEditCommentForm"
          v-if="commentEditActive === item.id"
        >
          <div
            id="repeatEditCommentInput"
            contenteditable
            data-type="old"
            :data-time="item.createDate"
            @keydown="commentFormKeyDown"
            @paste="commentFormPaste"
            @input="editTextNodeInput"
            v-html="item.content"
          ></div>
          <div
            style="padding-top: 12px;display:flex;justify-content: flex-end;"
          >
            <el-button
              id="repeatEditCommentSubmit"
              type="warning"
              size="small"
              @click="editCommentItemSubmit(item.createDate)"
              >确定</el-button
            >
          </div>
        </div>
        <div class="xpp-comment-item-con display-flex" v-else>
          <!-- 头像 -->
          <div
            class="xpp-comment-user-photo xpp-user-photo"
            :style="item.headImg | styleUserPhoto"
          ></div>
          <!-- 内容 -->
          <div class="xpp-comment-con-box">
            <!-- 内容发布者 -->
            <div class="xpp-comment-con-un display-flex">
              <span v-text="item.nickName"></span>
              <!-- 功能按钮 -->
              <div
                v-if="paperOperateMode === 2 && item.userId === xppUserInfo.id"
                :data-id="item.id"
                :data-time="item.createDate"
              >
                <b
                  class="xpp-comment-ibtn iconfont icon-edit"
                  title="编辑"
                  @click="editCommentItem($event, item)"
                ></b>
                <b
                  class="xpp-comment-ibtn iconfont icon-delete"
                  title="删除"
                  @click="delCommentItem(item.id)"
                ></b>
              </div>
            </div>
            <!-- 内容展示 -->
            <div class="xpp-comment-con-text" v-html="item.content"></div>
            <!-- 发布时间 -->
            <el-tooltip
              class="xpp-comment-con-time"
              :content="item.createDate | dateToLocale"
              placement="top"
            >
              <span>{{ item.createDate | toDateString }}</span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div
      id="xppCommentContentFooter"
      class="display-flex"
      v-if="paperOperateMode !== 1"
    >
      <!-- 头像 -->
      <div
        class="xpp-comment-user-photo xpp-user-photo"
        :style="xppUserInfo.headImg | styleUserPhoto"
      ></div>
      <div class="xpp-comment-form-text">
        <div class="display-flex">
          <div
            id="xppCommentContentText"
            ref="xppCommentContentText"
            data-type="creat"
            contenteditable
            @keydown="commentFormKeyDown"
            @paste="commentFormPaste"
            @input="editTextNodeInput"
          ></div>
          <span class="iconfont icon-tianjialianxiren"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { creatElementId } from "@plugins/toolWheel";

const VailUrlReg = /^(https?:\/\/)|mailto|tel/;

export default {
  name: "ElementComment",
  computed: {
    xppCommentOffset() {
      let style = {};
      let { top, left } = this.elementCommentInfo.offset;
      style.left = Math.floor(left * this.homeViewScale) + "px";
      style.top = Math.floor(top * this.homeViewScale) + "px";
      style.opacity = this.commentopacity;
      return style;
    },
    xppNotiCategoryInfo() {
      return {
        projec: this.paperCategoryList[0],
        paper: this.paperCategoryList.slice(-1)[0]
      };
    },
    commentInfoList() {
      return this.elementCommentInfo.list;
    },
    delAllCommentOff() {
      return (
        this.elementCommentInfo.list.length &&
        this.paperOperateMode === 2 &&
        this.xppUserInfo.id === 1
      );
    },
    ...mapGetters([
      "dialogElementShow",
      "elementCommentInfo",
      "xppUserInfo",
      "homeViewScale",
      "paperViewAll",
      "paperCategoryList",
      "xppContactNoticeInfo",
      "paperOperateMode"
    ])
  },
  watch: {
    "dialogElementShow.xppCommentShow"(val) {
      if (!val && this.paperOperateMode === 2) {
        this.commentopacity = 0;
        this.commentEditActive = null;
      }
      if (val) {
        this.$nextTick(() => {
          this.comBoxOffset();
        });
      } else {
        this.$DiaLoginFoUpDate({ key: "xppContactListShow", value: false });
      }
    }
  },
  data() {
    return {
      commentEditActive: null, // 被重新编辑评论的id
      // 被重新编辑的评论信息
      repeatEditCommentInfo: {
        id: "",
        content: ""
      },
      // 窗口透明
      commentopacity: 0,
      commentoffset: {
        top: "",
        left: ""
      },
      // 输入框内光标位置信息
      inputSelectionInfo: null
    };
  },
  methods: {
    // 评论窗口定位
    comBoxOffset() {
      this.commentopacity = 1;
      let dom = this.$refs.xppCommentContent,
        ofs = dom.getBoundingClientRect(),
        pageofs = dom.parentElement.getBoundingClientRect(),
        { top, left } = this.elementCommentInfo.offset;
      let viewH = document.documentElement.clientHeight - 45;
      if (ofs.bottom > viewH) {
        top = top - (ofs.bottom - viewH) / this.homeViewScale;
      }
      if (ofs.right > pageofs.right) {
        left = left - (ofs.right - pageofs.right) / this.homeViewScale;
      }
      Object.assign(this.elementCommentInfo.offset, {
        top,
        left
      });
      let body = this.$refs.xppCommentContentBody;
      body.scrollTo(0, body.scrollHeight);
      let input = document.getElementById("xppCommentContentText");
      setTimeout(() => {
        input && input.focus();
      }, 200);
    },
    // 删除单条评论
    delCommentItem(id) {
      let { commentId, targetData, list } = this.elementCommentInfo;
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          list.splice(i, 1);
        }
      }
      this.$WsSendMsg({
        data: {
          comments: [
            {
              id
            }
          ],
          commentId,
          _id: targetData.id
        },
        action: "COMMENTDELETE",
        type: "comments"
      });
      this.updataCommentsInfo();
    },
    // 删除所有评论
    delCommentAll() {
      let { commentId, targetData, list } = this.elementCommentInfo;
      list.splice(0, list.length);
      this.$WsSendMsg({
        data: {
          _id: targetData.id,
          commentId
        },
        action: "COMMENTDELELEMENT",
        type: "comments"
      });
      this.updataCommentsInfo();
      this.$DiaLoginFoUpDate({ key: "xppCommentShow", value: false });
    },
    // 对已有评论进行编辑
    editCommentItem(event, item) {
      let { target } = event;
      let parentElement = target.parentElement;
      this.commentEditActive = parentElement.dataset.id;
      this.repeatEditCommentInfo = item;
      this.$nextTick(() => {
        let dom = document.getElementById("repeatEditCommentInput");
        dom.focus();
        let cc = dom.childNodes;
        let range = document.createRange();
        let sel = window.getSelection();
        range.selectNode(cc[cc.length - 1]);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
        range.detach();
      });
    },
    // 提交重新编辑的评论内容
    editCommentItemSubmit(createDate) {
      this.elementAddComment(
        document.getElementById("repeatEditCommentInput"),
        this.commentEditActive,
        "old",
        createDate
      );
    },
    // 更新评论信息
    updataCommentsInfo() {
      let { commentId, targetData, offset, list } = this.elementCommentInfo;
      if (targetData.type === "imagesSequence") {
        let { clientHeight, clientWidth } = document.getElementById(
          targetData.id
        );
        if (list.length) {
          this.$set(targetData.commentsTotal, commentId, {
            total: list.length,
            style: {
              top: Math.floor((offset.oftt / clientHeight) * 100) + "%",
              left: Math.floor((offset.ofll / clientWidth) * 100) + "%"
            }
          });
        } else {
          this.$delete(targetData.commentsTotal, commentId);
        }
      } else {
        targetData.commentsTotal = list.length;
        targetData.commentId = commentId;
      }
      targetData.commentHistory = true;
      let pageIdx =
        targetData.page_index ||
        document.getElementById(targetData.page_id).dataset.index;
      this.$set(
        this.paperViewAll[pageIdx][targetData.type],
        targetData.id,
        targetData
      );
      let data = {
        action: "PUT",
        type: targetData.type,
        data: {
          _id: targetData.page_id,
          type: targetData.type
        }
      };
      data.data[data.type] = [{ id: targetData.id }];
      data.data[data.type][0][targetData.id] = targetData;
      this.$WsSendMsg(data);
      this.$nextTick(() => {
        this.comBoxOffset();
      });
    },
    // 添加评论 - 按键检测
    commentFormKeyDown(event) {
      switch (event.keyCode) {
        case 8: // 删除字符
          const selection = window.getSelection();
          let anchorNode = selection.anchorNode;
          let parentNode = selection.anchorNode.parentNode;
          let off = false;
          if (
            anchorNode.tagName === "SPAN" &&
            parentNode.id === "xppCommentContentText"
          ) {
            off = anchorNode.innerHTML.indexOf("@") >= 0;
            parentNode = anchorNode;
          } else if (parentNode.tagName === "SPAN") {
            off = parentNode.innerHTML.indexOf("@") >= 0;
          }
          if (off) {
            event.preventDefault();
            event.stopPropagation();
            parentNode.parentElement.removeChild(parentNode);
          }
          break;
        case 13: // 提交评论内容
          if (event.shiftKey || this.dialogElementShow.xppContactListShow) {
            return;
          }
          event.preventDefault();
          event.stopPropagation();
          this.elementAddComment(
            event.target,
            this.commentEditActive || creatElementId("comment"),
            event.target.dataset.type,
            event.target.dataset.time
          );
          break;
        case 27: // esc
          if (event.target.id === "xppCommentContentText") {
            if (this.dialogElementShow.xppContactListShow) {
              this.$DiaLoginFoUpDate({
                key: "xppContactListShow",
                value: false
              });
            } else {
              this.$DiaLoginFoUpDate();
              this.$SaveElementCommentInfo();
            }
          } else {
            this.commentEditActive = null;
          }
          break;
      }
    },
    // 评论输入内容检测
    editTextNodeInput(event) {
      switch (event.data) {
        case "@":
          event.preventDefault();
          event.stopPropagation();
          let { target } = event;
          let offset = target.getBoundingClientRect();
          this.$DiaLoginFoUpDate({ key: "xppContactListShow", value: true });
          this.$UpdataVuexState({ key: "xppQuillEditContainer", data: false });
          let data = {
            top: offset.top + offset.height + "px",
            left: offset.left + "px",
            width: offset.width + "px",
            bottom: "auto"
          };
          let bodyHeight = document.documentElement.clientHeight;
          if (bodyHeight - offset.bottom < 270) {
            data.top = "auto";
            data.bottom = bodyHeight - offset.top + "px";
          }
          this.$UpdataVuexState({ key: "xppContactDataInfo", data });
          let range = window.getSelection().getRangeAt(0);
          this.inputSelectionInfo = range.endOffset;
          this.$UpdataVuexState({
            key: "xppContactsFilterInfo",
            data: { text: "", selection: range }
          });
          break;
        default:
          if (this.inputSelectionInfo) {
            let inputRange = window.getSelection().getRangeAt(0);
            // 删除到@前方了
            if (inputRange.endOffset < this.inputSelectionInfo) {
              this.$DiaLoginFoUpDate({
                key: "xppContactListShow",
                value: false
              });
              return;
            }
            inputRange.setStart(
              inputRange.startContainer,
              this.inputSelectionInfo
            );
            this.$UpdataVuexState({
              key: "xppContactsFilterInfo",
              data: { text: inputRange.toString() }
            });
            inputRange.collapse();
          }
          break;
      }
    },
    // 粘贴剪贴板文字
    commentFormPaste(event) {
      event.preventDefault();
      let paste = event.clipboardData || window.clipboardData;
      let content = paste.getData("text/plain").replace(/\r?\n|\r/g, "<br>");
      if (VailUrlReg.test(content)) {
        content = `<a href="${content}" target="_blank" contenteditable="false" rel="noopener">${content}</a>`;
      }
      document.execCommand("insertHTML", false, content);
    },
    // 为节点添加评论
    elementAddComment(target, id, eventType, createDate) {
      let content = target.innerHTML;
      if (content.replace(/<br>|&nbsp;/g, "").trim() === "") {
        target.innerHTML = "";
        return;
      }
      let lastComment = null; // 最后一条评论（新增评论时被回复的）
      let time = new Date().getTime().toString();
      let comments = {
        updateDate: time,
        id,
        userId: this.xppUserInfo.id,
        headImg: this.xppUserInfo.headImg,
        nickName: this.xppUserInfo.nickName,
        content
      };
      this.commentEditActive = null;
      let { commentId, targetData, list } = this.elementCommentInfo;
      switch (eventType) {
        case "creat":
          comments.createDate = time;
          lastComment = list.slice(-1)[0];
          list.push(comments);
          break;
        case "old":
          for (let i = 0; i < list.length; i++) {
            if (list[i].id === id) {
              comments.createDate = createDate;
              list.splice(i, 1, comments);
            }
          }
          break;
      }

      let reciveuserIdList = [];
      for (let i in target.children) {
        let item = target.children[i];
        if (
          item.tagName === "SPAN" &&
          this.xppContactNoticeInfo[item.dataset.key]
        ) {
          reciveuserIdList.push(item.dataset.key);
        }
      }
      // 给被@的人发送通知
      if (reciveuserIdList.length) {
        this.sendNotifications({ reciveuserIdList });
        this.$UpdataVuexState({ key: "xppContactNoticeInfo", data: {} });
      }
      // 节点首次被评论时给节点创建人发通知
      if (
        !targetData.commentHistory &&
        targetData.createUserID !== this.xppUserInfo.id
      ) {
        this.sendNotifications({
          reciveuserIdList: [targetData.createUserID],
          remindType: "5"
        });
      }
      // 给评论被回复的人发送通知
      if (lastComment && lastComment.userId !== this.xppUserInfo.id) {
        this.sendNotifications({
          reciveuserIdList: [lastComment.userId],
          remindType: "4"
        });
      }
      let { paper } = this.xppNotiCategoryInfo;
      this.$WsSendMsg({
        data: {
          _id: targetData.id,
          comments: [comments],
          commentId
        },
        action: eventType === "creat" ? "COMMENTPUSH" : "COMMENTUPDATE",
        type: "comments",
        groupName: paper.name
      });
      target.innerHTML = "";

      this.$nextTick(() => {
        this.updataCommentsInfo();
      });
    },
    async sendNotifications(data) {
      let { commentId, targetData } = this.elementCommentInfo;
      let { paper, projec } = this.xppNotiCategoryInfo;
      let reciveuserIdList = {};
      for (let i = 0; i < data.reciveuserIdList.length; i++) {
        reciveuserIdList[data.reciveuserIdList[i]] = true;
      }
      data.reciveuserIdList = Object.keys(reciveuserIdList);
      data = Object.assign(
        {
          elementId: targetData.id,
          commentId,
          paperId: paper.id,
          paperName: paper.name,
          projectId: projec.id,
          projectName: projec.name,
          reciveuserIdList: [],
          remindType: "1",
          reminduserHeadimg: this.xppUserInfo.headImg,
          reminduserId: this.xppUserInfo.id,
          reminduserName: this.xppUserInfo.nickName
        },
        data
      );
      this.$axios.post("/dis/rem/add", data);
    },
    ...mapMutations([
      "$DiaLoginFoUpDate",
      "$SaveElementCommentInfo",
      "$UpdataVuexState"
    ]),
    ...mapActions(["$WsSendMsg"])
  }
};
</script>

<style lang="scss" scoped>
#xppCommentContent {
  position: absolute;
  z-index: 1000;
  top: -100vh;
  left: -100vw;
  width: 408px;
  border: 1px solid #dbdee6;
  box-shadow: 0 9px 40px 0 rgba(28, 30, 31, 0.14);
  background-color: #fff;
  border-radius: 3px;
}

#xppCommentContentHead {
  padding-left: 24px;
  padding-right: 24px;
  color: #858c93;
  font-size: 16px;
  height: 34px;
  border-bottom: 1px solid #e9ebec;
  .iconfont {
    cursor: pointer;
  }
}

#xppCommentContentBody {
  max-height: calc(100vh - 300px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 11px;
  }
  &::-webkit-scrollbar-thumb {
    border: 2px solid #fff;
    background: #eaeaea;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    width: 9px;
    background: #fff;
    margin: 5px 0;
  }
}

.xpp-comment-item {
  background-color: #ffffff;
  position: relative;
  &:hover {
    background-color: #f5f7fa;
    .xpp-comment-ibtn {
      opacity: 1;
    }
  }
  .xpp-comment-item-con {
    align-items: flex-start;
    padding-top: 11px;
    padding-bottom: 8px;
  }
}

.xpp-comment-user-photo {
  flex: none;
  width: 32px;
  height: 32px;
  margin-left: 20px;
  margin-right: 16px;
  box-shadow: 0 0 4px 0 #787878;
}

.xpp-comment-con-box {
  flex: auto;
  margin-right: 30px;
  font-size: 14px;
  font-weight: 700;
  max-width: calc(100% - 98px);
  box-sizing: border-box;
}

.xpp-comment-con-un {
  height: 24px;
  .iconfont {
    display: inline-block;
    color: #c2c6d1;
    margin-right: 12px;
    font-weight: lighter;
    opacity: 0;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #999999;
    }
  }
}

.xpp-comment-con-text {
  font-weight: normal;
  line-height: 1.8;
  white-space: normal;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  user-select: text;
}

.xpp-comment-con-time {
  position: relative;
  font-size: 13px;
  height: 28px;
  line-height: 28px;
  font-weight: 400;
  color: #8e959a;
}

#xppCommentContentFooter {
  align-items: flex-start;
  padding-bottom: 14px;
  padding-top: 8px;
  .xpp-comment-user-photo {
    width: 37px;
    height: 37px;
  }
}

.xpp-comment-form-text {
  position: relative;
  flex: none;
  width: 305px;
  color: #54626c;
  background-color: #fff;
  border: 1px solid #c9d0d5;
  box-shadow: 0 0 0 1px transparent;
  border-radius: 4px;
  margin-right: 30px;
  .iconfont {
    font-size: 22px;
    position: absolute;
    top: 6px;
    right: 10px;
    color: #b5b5b5;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #54626c;
    }
  }
}
#xppCommentContentText {
  flex: auto;
}

#xppCommentContentText,
#repeatEditCommentInput {
  padding: 6px 40px 6px 12px;
  line-height: 1.8 !important;
  font-size: 14px;
  outline: none;
  font-family: inherit;
  max-height: 60vh;
  max-width: 100%;
  min-height: 25px;
  cursor: text;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
}

#repeatEditCommentInput {
  border: 1px solid #c9d0d5;
  border-radius: 4px;
}
#repeatEditCommentForm {
  padding: 12px 24px;
  box-sizing: border-box;
  width: 100%;
  background-color: #f5f7fa;
  justify-content: flex-start;
}

::v-deep {
  #xppCommentContentText,
  #repeatEditCommentInput,
  .xpp-comment-con-text {
    a {
      color: #06c;
      text-decoration: underline;
      margin: 0 3px;
    }
  }
  #xppCommentContentText,
  #repeatEditCommentInput {
    a {
      pointer-events: none;
    }
  }
}
</style>
